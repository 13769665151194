import {
  createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";
//import { createRouter } from "vue-router";
//import SoonPage from "../views/SoonPage.vue";

const routes = [
  {
    path: "/",
    name: "Soon",
    // component: SoonPage,
    component: () => import("../views/SoonPage.vue"),
  },
  {
    path: "/testpage",
    name: "TestPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TestPage.vue"),
  },
  // catchall 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/NotFoundError404Page.vue"),
  },
];

// https://v3.vuejs.org/guide/ssr/routing.html#routing-with-vue-router
const isServer = typeof window === "undefined";
const createHistory = isServer ? createMemoryHistory : createWebHistory;

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  mode: "hash",
  history: createHistory(),
  routes,
});

// const router = new createRouter({
//   routes,
// })

export default router;
